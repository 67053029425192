import { Data } from "app/app-web/app-model/utils/dto/data.model";

export class ExamConditionEnum {

    public static readonly NORMAL: Data = { id: 1, name: "lblNormal" };
    public static readonly EASY: Data = { id: 2, name: "lblEasy" };
    public static readonly DIFFICULT: Data = { id: 3, name: "lblDifficult" };

	public static getExamConditionList() {
		return [ExamConditionEnum.NORMAL, ExamConditionEnum.EASY,
		ExamConditionEnum.DIFFICULT];
	}

}
import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs/internal/BehaviorSubject";
import { Ultrasound } from "app/app-web/app-model/dto/gynecology/ultrasound.model";
import { Subject } from "rxjs/internal/Subject";
import { Allergy } from "app/app-web/app-model/dto/common/allergy.model";
import { AppointmentType } from "app/app-web/app-model/dto/common/appointment-type.model";
import { BacteriologyGerm } from "app/app-web/app-model/dto/common/bacteriology-germ.model";
import { BacteriologyType } from "app/app-web/app-model/dto/common/bacteriology-type.model";
import { BiologyType } from "app/app-web/app-model/dto/common/biology-type.model";
import { City } from "app/app-web/app-model/dto/common/city.model";
import { ContraceptiveMethod } from "app/app-web/app-model/dto/common/contraceptive-method.model";
import { Convention } from "app/app-web/app-model/dto/common/convention.model";
import { CytologyPathologyType } from "app/app-web/app-model/dto/common/cytology-pathology-type.model";
import { Drug } from "app/app-web/app-model/dto/common/drug.model";
import { FamilyPathology } from "app/app-web/app-model/dto/common/family-pathology.model";
import { FinancialAccountingType } from "app/app-web/app-model/dto/common/financial-accounting-type.model";
import { Insurance } from "app/app-web/app-model/dto/common/insurance.model";
import { Investigation } from "app/app-web/app-model/dto/common/investigation.model";
import { MedicalPathology } from "app/app-web/app-model/dto/common/medical-pathology.model";
import { OperationPlace } from "app/app-web/app-model/dto/common/operation-place.model";
import { OperativeReportModel } from "app/app-web/app-model/dto/common/operative-report-model.model";
import { OperativeReportType } from "app/app-web/app-model/dto/common/operative-report-type.model";
import { OtherDoctor } from "app/app-web/app-model/dto/common/other-doctor.model";
import { PatientAllergyHistory } from "app/app-web/app-model/dto/common/patient-allergie-history.model";
import { PatientContraceptionHistory } from "app/app-web/app-model/dto/common/patient-contraception-history.model";
import { PatientFamilyHistory } from "app/app-web/app-model/dto/common/patient-family-history.model";
import { PatientLifeStyleHistory } from "app/app-web/app-model/dto/common/patient-life-style-history.model";
import { PatientMedicalHistory } from "app/app-web/app-model/dto/common/patient-medical-history.model";
import { PatientPregnancyHistory } from "app/app-web/app-model/dto/gynecology/patient-pregnancy-history.model";
import { PatientSurgicalHistory } from "app/app-web/app-model/dto/common/patient-surgical-history.model";
import { Patient } from "app/app-web/app-model/dto/common/patient.model";
import { PrescriptionModel } from "app/app-web/app-model/dto/common/prescription-model.model";
import { RadiologyType } from "app/app-web/app-model/dto/common/radiology-type.model";
import { SerologyType } from "app/app-web/app-model/dto/common/serology-type.model";
import { SocialSecurity } from "app/app-web/app-model/dto/common/social-security.model";
import { SurgicalPathology } from "app/app-web/app-model/dto/common/surgical-pathology.model";
import { GeneralMedicineReason } from "app/app-web/app-model/dto/general-medicine/general-medicine-reason.model";
import { FertilityAnesthesia } from "app/app-web/app-model/dto/gynecology/fertility-anesthesia.model";
import { FertilityCervix } from "app/app-web/app-model/dto/gynecology/fertility-cervix.model";
import { FertilityControlledOvarianHyperstimulationDrug } from "app/app-web/app-model/dto/gynecology/fertility-controlled-ovarian-hyperstimulation-drug.model";
import { FertilityControlledOvarianHyperstimulationHormone } from "app/app-web/app-model/dto/gynecology/fertility-controlled-ovarian-hyperstimulation-hormone.model";
import { FertilityEtiology } from "app/app-web/app-model/dto/gynecology/fertility-etiology.model";
import { FertilityMapCenter } from "app/app-web/app-model/dto/gynecology/fertility-map-center.model";
import { FertilityMapMethod } from "app/app-web/app-model/dto/gynecology/fertility-map-method.model";
import { FertilityMap } from "app/app-web/app-model/dto/gynecology/fertility-map.model";
import { FertilityPretreatment } from "app/app-web/app-model/dto/gynecology/fertility-pretreatment.model";
import { FertilityPreviousStimulationType } from "app/app-web/app-model/dto/gynecology/fertility-previous-stimulation-type.model";
import { FertilityProtocol } from "app/app-web/app-model/dto/gynecology/fertility-protocol.model";
import { FertilityReason } from "app/app-web/app-model/dto/gynecology/fertility-reason.model";
import { FertilitySpermOrigin } from "app/app-web/app-model/dto/gynecology/fertility-sperm-origin.model";
import { FertilityTherapeutique } from "app/app-web/app-model/dto/gynecology/fertility-therapeutique.model";
import { FertilityTube } from "app/app-web/app-model/dto/gynecology/fertility-tube.model";
import { FertilityUterus } from "app/app-web/app-model/dto/gynecology/fertility-uterus.model";
import { GynecoCystOvary } from "app/app-web/app-model/dto/gynecology/gyneco-cyst-ovary.model";
import { GynecoPelvicMassPresumption } from "app/app-web/app-model/dto/gynecology/gyneco-pelvic-mass-presumption.model";
import { GynecoReason } from "app/app-web/app-model/dto/gynecology/gyneco-reason.model";
import { HormonalStatus } from "app/app-web/app-model/dto/gynecology/hormonal-status.model";
import { PregnancyFollowUpScheduleIntervention } from "app/app-web/app-model/dto/gynecology/pregnancy-follow-up-schedule-intervention.model";
import { PregnancyGeneticSample } from "app/app-web/app-model/dto/common/pregnancy-genetic-sample.model";
import { PregnancyGeneticType } from "app/app-web/app-model/dto/common/pregnancy-genetic-type.model";
import { PregnancyReason } from "app/app-web/app-model/dto/gynecology/pregnancy-reason.model";
import { Data } from "app/app-web/app-model/utils/dto/data.model";
import { PatientPregnancyGeneralHistory } from "app/app-web/app-model/dto/general-medicine/patient-pregnancy-general-history.model";
import { DocumentStructure } from "app/app-web/app-model/dto/common/document-structure.model";
import { TagName } from "app/app-web/app-model/dto/common/tag-name.model";
import { PregnancySummaryModel } from "app/app-web/app-model/dto/gynecology/pregnancy-summary-model.model";
import { PregnancyConclusionModel } from "app/app-web/app-model/dto/gynecology/pregnancy-conclusion-model.model";
import { PregnancyUltrasoundEmptyModel } from "app/app-web/app-model/dto/common/pregnancy-ultrasound-empty-model.model";


@Injectable()
export class DataSharedService {
	
	// Subjects
	public modifyAppointmentSchedulerEvent: Subject<void> = new Subject();
	public modifyAppointmentWaitingRoomEvent: Subject<void> = new Subject();
	public modifyInvestigationListEvent: Subject<void> = new Subject();
	public modifyOtherDoctorListEvent: Subject<void> = new Subject();
	public minimizedConsultation: Subject<any> = new Subject();
	
	// App
	public appId: number;
	public appSubscription: any;
	public appCountryCode: any;
	
	// Authoriy
	public authorityList: string[];
	
	// FinancialAccounting
    public financialAccountingSettings: {
        financialAccountingTypeList?: FinancialAccountingType[];
    };
	
	// AppointmentSettings
    public appointmentSettings: {
        appointmentTypeList?: AppointmentType[];
    };
    
    // Document Structure
    public documentStructure: DocumentStructure | any;

    // Patient
    public patient: Patient;

	// GeneralMedicine
    public generalMedicineSettings: {
        generalMedicineReasonList?: GeneralMedicineReason[];
    };

    // Pregnancy
    public pregnancySettings: {
        pregnancyFollowUpScheduleInterventionList?: PregnancyFollowUpScheduleIntervention[];
    };

    // Gyneco
    public gynecoSettings: {
        gynecoReasonList?: GynecoReason[];
        hormonalStatusList?: HormonalStatus[];
    };
    
    // Fertility
    public fertilitySettings: {
        fertilityControlledOvarianHyperstimulationDrugList?: FertilityControlledOvarianHyperstimulationDrug[];
        fertilityControlledOvarianHyperstimulationHormoneList?: FertilityControlledOvarianHyperstimulationHormone[];
        fertilityPretreatmentList?: FertilityPretreatment[];
        fertilityProtocolList?: FertilityProtocol[];
        fertilityMapList?: FertilityMap[];
		fertilityMapCenterList?: FertilityMapCenter[];
		fertilityCervixList?: FertilityCervix[];
		fertilityUterusList?: FertilityUterus[];
		fertilityTubeList?: FertilityTube[];
		fertilityAnesthesiaList?: FertilityAnesthesia[];
		fertilitySpermOriginList?: FertilitySpermOrigin[];
		fertilityMapMethodList?: FertilityMapMethod[];
		fertilityPreviousStimulationTypeList?: FertilityPreviousStimulationType[];
		fertilityEtiologyList?: FertilityEtiology[];
		fertilityTherapeutiqueList?: FertilityTherapeutique[];
		fertilityReasonList?: FertilityReason[];
    };
    
    // ConsultationSettings
    public consultationSettings: {
		ultrasoundList?: Ultrasound[];
        bacteriologyTypeList?: BacteriologyType[];
        bacteriologyGermList?: BacteriologyGerm[];
        biologyTypeList?: BiologyType[];
        serologyTypeList?: SerologyType[];
        cytologyPathologyTypeList?: CytologyPathologyType[];
        radiologyTypeList?: RadiologyType[];
        pregnancyGeneticTypeList?: PregnancyGeneticType[];
        pregnancyGeneticSampleList?: PregnancyGeneticSample[];
        gynecoCystOvaryList?: GynecoCystOvary[];
        gynecoPelvicMassPresumptionList?: GynecoPelvicMassPresumption[];
        pregnancyReasonList?: PregnancyReason[];
		drugList?: Drug[];
		investigationList?: Investigation[];
		prescriptionModelList?: PrescriptionModel[];
		pregnancySummaryModelList?: PregnancySummaryModel[];
		pregnancyConclusionModelList?: PregnancyConclusionModel[];
		pregnancyUltrasoundEmptyModelList?: PregnancyUltrasoundEmptyModel[];
		operativeReportTypeList?: OperativeReportType[];
		operativeReportModelList?: OperativeReportModel[];
		otherDoctorList?: OtherDoctor[];
    };
    
    //commun
    public tagNameList: TagName[];

    // Patient Parameters
    public cityList: City[];
    public countryList: Data[];
    public socialSecurityList: SocialSecurity[];
    public insuranceList: Insurance[];
    public conventionList: Convention[];

    // Patient History Parameters
    public contraceptiveMethodList: ContraceptiveMethod[];
    public medicalPathologyList: MedicalPathology[];
    public allergyList: Allergy[];
    public surgicalPathologyList: SurgicalPathology[];
    public familyPathologyList: FamilyPathology[];
    public operationPlaceList: OperationPlace[];
    public patientAlleryHistoryList: PatientAllergyHistory[];
    public patientSurgicalHistoryList: PatientSurgicalHistory[];
    public patientPregnancyHistoryList: PatientPregnancyHistory[];
    public patientPregnancyGeneralHistoryList: PatientPregnancyGeneralHistory[];
    public patientFamilyHistoryList: PatientFamilyHistory[];
    public patientContraceptionHistoryList: PatientContraceptionHistory[];
    public patientMedicalHistoryList: PatientMedicalHistory[];
    public patientLifeStyleHistory : PatientLifeStyleHistory;

    constructor() {
        this.generalMedicineSettings = {};
        this.pregnancySettings = {};
        this.gynecoSettings = {};
        this.fertilitySettings = {};
        this.consultationSettings = {};
        this.appointmentSettings = {};
		this.financialAccountingSettings = {};
    }

	/**
	 * reset the data of patient
	 */
	resetPatientData() {
		this.patient = {};
		this.patientAlleryHistoryList = [];
		this.patientSurgicalHistoryList = [];
		this.patientPregnancyHistoryList = [];
		this.patientPregnancyGeneralHistoryList = [];
		this.patientFamilyHistoryList = [];
		this.patientContraceptionHistoryList = [];
		this.patientMedicalHistoryList = [];
		this.patientLifeStyleHistory = {};
	}

}
